import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  CardContent,
  Tooltip,
  Link,
} from '@mui/material';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { Skeleton } from '@mui/material';
import { useAccessLogs } from 'api/siteMetrics';
import { useSiteDetails } from 'api/site';
import { AccessLogRow } from 'api/siteMetrics';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faDesktop, faTablet } from '@fortawesome/pro-regular-svg-icons';
import CountryName from '../../base/CountryName';
import PaginationControls from 'component/base/PaginationControls';
import { AccessLogFilters } from './AccessLogFilters';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import {
  ACCESS_LOG_FILTER_KEYS,
  AccessLogFilterKey,
} from 'component/new_design/partial/tables/logs/common';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { EMPTY_VALUE_PLACEHOLDER } from 'utils/formatting';

function UppercaseFirstLetter(str: string = '') {
  return `${str[0]?.toUpperCase() ?? ''}${str.slice(1)}`;
}

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

function AccessLogTableRow({
  accessLogItem,
  domain,
}: {
  readonly accessLogItem: AccessLogRow;
  readonly domain: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const row = (
    <TableRow
      className={open ? 'is-active' : undefined}
      onClick={() => setOpen(!open)}
      style={{ cursor: 'pointer' }}
    >
      <TableCell
        sx={{
          verticalAlign: 'top',
          fontWeight: 'normal !important' as 'normal',
        }}
      >
        <>
          {accessLogItem.timestamp
            ? formatDate({
                date: accessLogItem.timestamp,
                dateStyle: 'short',
                timeStyle: 'short',
              })
            : EMPTY_VALUE_PLACEHOLDER}
        </>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Box display="inline-block" marginRight={1.5}>
          {accessLogItem.ClientDeviceType === 'desktop' && <FontAwesomeIcon icon={faDesktop} />}
          {accessLogItem.ClientDeviceType === 'mobile' && <FontAwesomeIcon icon={faMobile} />}
          {accessLogItem.ClientDeviceType === 'tablet' && <FontAwesomeIcon icon={faTablet} />}
        </Box>
        {accessLogItem.ClientIP}
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <CountryName countryCode={accessLogItem.ClientCountry?.toUpperCase() || ''} />
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Tooltip title={`https://${domain}${accessLogItem.ClientRequestURI}`}>
          <Link
            href={`https://${domain}${accessLogItem.ClientRequestURI}`}
            target="_blank"
            style={{
              display: 'inline-block',
              maxWidth: 400,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {accessLogItem.ClientRequestURI}
          </Link>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        {UppercaseFirstLetter(accessLogItem.CacheCacheStatus)}
      </TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>{accessLogItem.EdgeResponseStatus}</TableCell>
    </TableRow>
  );

  if (open) {
    return (
      <>
        {row}
        <TableRow className="is-collapse">
          <TableCell colSpan={6}>
            <Box padding={1}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <strong>{t('access_log_filters.ray_id')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.RayID}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestHTTPMethodName')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestMethod}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestHTTPHost')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestHTTPHost}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('path')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestURI?.split('?')[0]}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('clientRequestQuery')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography color="text.secondary">
                    {accessLogItem.ClientRequestURI?.split('?')[1] ?? <i>{t('empty_string')}</i>}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <strong>{t('userAgent')}</strong>
                </Grid>
                <Grid item xs={9}>
                  <Typography>{accessLogItem.ClientRequestUserAgent}</Typography>
                </Grid>
              </Grid>
            </Box>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return row;
}

export function AccessLog({
  siteId,
  duration,
}: {
  readonly siteId: string;
  readonly duration: string;
}) {
  const siteDetails = useSiteDetails({ id: siteId });
  const domain = siteDetails.data?.data.result?.domain ?? '';

  const tableState = usePaginatedListState(
    {
      perPageOptions: [10, 50, 100, 250, 500],
      urlStatePrefix: 'access-log',
      filterKeys: ACCESS_LOG_FILTER_KEYS as unknown as AccessLogFilterKey[],
    },
    { filters: { duration: '1h' } }
  );

  const isDurationInitialized = useRef(false);

  useEffect(() => {
    tableState.setFilter('duration', duration);
    isDurationInitialized.current = true;
  }, [duration]);

  const { isLoading, data } = useAccessLogs(
    siteId,
    domain,
    tableState,
    isDurationInitialized.current
  );

  const { t } = useTranslation();
  const accessLogItems = data?.data?.result || [];

  const loadingState = usePaginatedListLoadingState(
    !!accessLogItems.length,
    isLoading,
    tableState.params
  );

  const noDataTitle =
    tableState.params.filters.duration === '72h'
      ? t('no_data_yet')
      : t('no_results_during_this_timeframe');

  const renderTableRows = () => {
    if (isLoading) {
      return <RowSkeleton />;
    }

    return accessLogItems.map(accessLogItem => {
      return (
        <AccessLogTableRow
          key={accessLogItem.RayID}
          accessLogItem={accessLogItem}
          domain={domain}
        />
      );
    });
  };

  return (
    <>
      <AccessLogFilters tableState={tableState} totalCount={data?.data.metadata?.total ?? null} />
      <CardContent>
        <TableContainer>
          <Table aria-label="Site Access Logs">
            <TableHead>
              <TableRow>
                <TableCell width={170}>{t('date')}</TableCell>
                <TableCell>{t('deviceOrIp')}</TableCell>
                <TableCell>{t('country')}</TableCell>
                <TableCell width={300}>{t('url')}</TableCell>
                <TableCell>{t('cache')}</TableCell>
                <TableCell width={130}>{t('status_code')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingState === 'noResults' ? (
                <NoResultsFound colSpan={7} />
              ) : loadingState === 'noData' ? (
                <NoResultsFound colSpan={7} message={noDataTitle} />
              ) : (
                renderTableRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControls
          page={tableState.params.page}
          totalRowCount={data?.data.metadata?.total || 0}
          perPage={tableState.params.perPage}
          perPageOptions={tableState.config.perPageOptions}
          onPageChange={tableState.setPage}
          onPerPageChange={tableState.setPerPage}
        />
      </CardContent>
    </>
  );
}
