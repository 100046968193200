import {
  Box,
  ClickAwayListener,
  Chip,
  Divider,
  Fade,
  Grid,
  Paper,
  Popper,
  Typography,
  Button,
  Stack,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from '@mui/material';
import { useState, useRef } from 'react';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronRight, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField, Option } from 'component/base/TextField';
import { cssVar } from 'utils/css';
import { useBuildSelectOptions } from 'component/hooks/useBuildSelectOptions';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import {
  ACCESS_LOG_FILTER_KEYS,
  AccessLogFilterKey,
} from 'component/new_design/partial/tables/logs/common';

function TextFilter({
  filter,
  clearSelectedFilter,
  setFilter,
  closeMenu,
  filterValue,
  options,
}: {
  readonly filter: AccessLogFilterKey;
  readonly clearSelectedFilter: () => void;
  readonly setFilter: (filterValue: string | undefined) => void;
  readonly closeMenu: () => void;
  readonly filterValue?: string;
  readonly options?: Option[];
}) {
  const { t } = useTranslation();
  const methods = useForm<{ filter: string }>({
    defaultValues: {
      filter: filterValue ?? '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: { filter: string }) => {
    setFilter(data.filter !== '' ? data.filter : undefined);
  };

  return (
    <Box padding={2}>
      <Button
        variant="text"
        color="primary"
        onClick={clearSelectedFilter}
        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        {t('back')}
      </Button>
      <Box paddingTop={2} paddingBottom={2}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{
                '& label': {
                  fontSize: '.875rem',
                },
              }}
              data-testid={filter}
              name="filter"
              label={t(`access_log_filters.${filter}`)}
              fullWidth
              options={options}
            />
          </form>
        </FormProvider>
      </Box>
      <Stack direction="row" spacing={2} alignItems="baseline">
        <Button
          type="submit"
          data-testid="filterApplyButton"
          color="primary"
          variant="contained"
          onClick={() => {
            handleSubmit(onSubmit)();
            closeMenu();
          }}
        >
          {t('apply')}
        </Button>
        <Typography>{t('or')}</Typography>
        <Button
          data-testid="closeButton"
          variant="text"
          onClick={() => {
            closeMenu();
          }}
          color="primary"
        >
          {t('close')}
        </Button>
      </Stack>
    </Box>
  );
}

export default function RowsSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

export function AccessLogFilters({
  tableState,
  totalCount,
}: {
  readonly tableState: PaginatedListState<AccessLogFilterKey>;
  readonly totalCount: number | null;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<AccessLogFilterKey | null>(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { buildOptions } = useBuildSelectOptions();

  const appliedFilters = tableState.params.filters;

  const renderPopupBody = (): ReactNode => {
    switch (selectedFilter) {
      case 'ip':
      case 'ray_id':
      case 'status_code':
      case 'uri':
        return (
          <TextFilter
            filter={selectedFilter}
            filterValue={selectedFilter ? appliedFilters[selectedFilter] : undefined}
            clearSelectedFilter={() => setSelectedFilter(null)}
            setFilter={filterValue => {
              tableState.setFilter(selectedFilter, filterValue);
            }}
            closeMenu={() => {
              setShowDropdown(false);
              setSelectedFilter(null);
            }}
          />
        );
      case 'device_type':
        return (
          <TextFilter
            filter={selectedFilter}
            filterValue={selectedFilter ? appliedFilters[selectedFilter] : undefined}
            clearSelectedFilter={() => setSelectedFilter(null)}
            setFilter={filterValue => {
              tableState.setFilter(selectedFilter, filterValue);
            }}
            closeMenu={() => {
              setShowDropdown(false);
              setSelectedFilter(null);
            }}
            options={buildOptions('desktop', 'tablet', 'phone')}
          />
        );
      case 'cache_status':
        return (
          <TextFilter
            filter={selectedFilter}
            filterValue={selectedFilter ? appliedFilters[selectedFilter] : undefined}
            clearSelectedFilter={() => setSelectedFilter(null)}
            setFilter={filterValue => {
              tableState.setFilter(selectedFilter, filterValue);
            }}
            closeMenu={() => {
              setShowDropdown(false);
              setSelectedFilter(null);
            }}
            options={buildOptions('bypass', 'dynamic', 'expired', 'hit', 'miss', 'none', 'stable')}
          />
        );
      case null:
      default:
        return (
          <TableContainer>
            <Table aria-label="Access Filters Table">
              <TableBody>
                {ACCESS_LOG_FILTER_KEYS.filter(filter => filter !== 'duration').map(filter => (
                  <TableRow
                    key={filter}
                    sx={{
                      cursor: 'pointer',
                      '&:hover td': {
                        backgroundColor: cssVar('--primary-menu-active'),
                      },
                    }}
                    onClick={() => {
                      setSelectedFilter(filter);
                    }}
                  >
                    <TableCell>
                      <Typography>{t(`access_log_filters.${filter}`)}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: cssVar('--primary'),
                        width: 40,
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
    }
  };

  let selectedFilters = 0;

  return (
    <>
      <Button
        aria-describedby="accessLogFilterMenuPopper"
        aria-controls="filter-menu"
        aria-haspopup="true"
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        variant="outlined"
        color="primary"
        ref={anchorRef}
      >
        <Box
          display="inline-block"
          marginRight={0.5}
          sx={{
            '& svg': {
              height: '18px',
              verticalAlign: 'middle',
            },
          }}
        >
          <FontAwesomeIcon icon={faFilter} />
        </Box>
        {t('add_filter')}
      </Button>
      <Popper
        id="accessLogFilterMenuPopper"
        open={showDropdown}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        style={{ zIndex: 1, width: '400px' }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setShowDropdown(false);
            }}
            mouseEvent="onMouseUp"
          >
            <Fade {...TransitionProps} timeout={350}>
              <Paper>{renderPopupBody()}</Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Box marginTop={1}>
        <Grid container spacing={1}>
          {Object.keys(appliedFilters)
            .filter((filter => filter !== 'duration') as (f: string) => f is AccessLogFilterKey)
            .map(key => {
              if (appliedFilters[key]) {
                selectedFilters++;

                return (
                  <Grid item key={key}>
                    <Chip
                      label={
                        <>
                          {t(`access_log_filters.${key}`)}
                          {': '}
                          <Box
                            sx={{
                              display: 'inline',
                              fontWeight: 'bold',
                              color: 'grey.700',
                            }}
                          >
                            {appliedFilters[key]}
                          </Box>
                        </>
                      }
                      onDelete={() => {
                        setSelectedFilter(null);
                        tableState.setFilter(key, undefined);
                      }}
                      onClick={() => {
                        setSelectedFilter(key);
                        setShowDropdown(true);
                      }}
                      sx={{
                        backgroundColor: 'grey.200',
                        color: 'grey.600',
                        height: '1.65rem',
                        pointerEvents: 'all',
                      }}
                      clickable
                      deleteIcon={<ClearIcon />}
                    />
                  </Grid>
                );
              }
              return null;
            })}
          {totalCount ? (
            <>
              {selectedFilters ? (
                <Grid item>
                  <Divider
                    orientation="vertical"
                    sx={{
                      marginLeft: '10px',
                      marginRight: '10px',
                      height: '70%',
                      marginTop: '15%',
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <Typography color="textPrimary">{t('result', { count: totalCount })}</Typography>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </>
  );
}
