import { ChangeEvent, useState } from 'react';
import { Card, CardHeader, CardContent, MenuItem, TextField, Tab, Tabs } from '@mui/material';
import { ActivityLog } from 'component/partial/ActivityLog';
import { AccessLog } from 'component/partial/AccessLog';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SiteDetail } from '../../../../api/site';

type LogType = 'access' | 'activity';

const Logs = ({ siteDetails }: { readonly siteDetails?: SiteDetail }) => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const staticSite: boolean = siteDetails?.site_type === 1 || false;
  const initialState: LogType = staticSite ? 'access' : 'activity';
  const [logType, setLogType] = useState<LogType>(initialState);

  const timeframes = [
    {
      label: t('last_30_minutes'),
      value: '30m',
    },
    {
      label: t('last_1_hour'),
      value: '1h',
    },
    {
      label: t('last_6_hours'),
      value: '6h',
    },
    {
      label: t('last_12_hours'),
      value: '12h',
    },
    {
      label: t('last_24_hours'),
      value: '24h',
    },
    {
      label: t('last_72_hours'),
      value: '72h',
    },
    {
      label: t('last_7_days'),
      value: '7d',
    },
    {
      label: t('last_30_days'),
      value: '30d',
    },
  ];

  const [timeframe, setTimeframe] = useState<string>(logType === 'activity' ? '24h' : '1h');

  return (
    <Card>
      <CardHeader
        action={
          <TextField
            id="select-timeframe"
            select
            value={timeframe}
            variant="outlined"
            onChange={e => setTimeframe(e.target.value)}
          >
            {timeframes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        title={
          <Tabs
            aria-label="tabs"
            variant="scrollable"
            onChange={(_event: ChangeEvent<any>, tabValue: LogType) => {
              if (tabValue === 'activity') {
                setTimeframe('24h');
              } else {
                setTimeframe('1h');
              }
              setLogType(tabValue);
            }}
            value={logType}
          >
            {!staticSite ? <Tab label={t('activity_log_card_header')} value="activity" /> : null}
            <Tab label={t('access_logs')} value="access" />
          </Tabs>
        }
      />
      <CardContent>
        {logType === 'activity' && <ActivityLog siteId={siteId} duration={timeframe} />}
        {logType === 'access' && <AccessLog siteId={siteId} duration={timeframe} />}
      </CardContent>
    </Card>
  );
};

export default Logs;
