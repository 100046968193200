import { FC } from 'react';
import { Box, Divider, Grid, Typography, List, ListItem, Stack, Skeleton } from '@mui/material';
import ContentViewCard from 'component/base/ContentViewCard';
import { useParams } from 'react-router-dom';
import { settingsGrid, settingsIcon } from 'theme/custom/settings';
import { useTranslation } from 'react-i18next';
import SSHAccess from 'component/svg/settings/ssh-access.svg?react';
import LockMySite from 'component/svg/settings/lock-my-site.svg?react';
import ToggleSetting from 'component/view/SiteDetails/Advanced/components/SiteSettings/ToggleSetting';
import {
  useGetPasswordProtectionStatus,
  useSetSiteProtection,
  useDisableLockSite,
  useEnableLockSite,
} from 'api/security';
import UserList from './UserList';
import { useSiteDetails } from 'api/site';
import { getSiteLockedStatus } from 'utils/site';
import { RoleGuard } from 'component/base/RoleGuard';
import { useRoleGuard } from 'component/hooks/useRoleGuard';

export interface ChangeDataSchema {
  key: string;
  value: number;
}

export const Security: FC = () => {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const passwordProtection = useGetPasswordProtectionStatus({ siteId });
  const siteDetails = useSiteDetails({ id: siteId });
  const { t } = useTranslation();

  const { mutateAsync: setSiteProtection } = useSetSiteProtection({ siteId });
  const passwordProtectionValue = passwordProtection.data?.data.result?.enabled ?? false;

  const { mutateAsync: disableLockSite } = useDisableLockSite({ siteId });
  const { mutateAsync: enableLockSite } = useEnableLockSite({ siteId });
  const lockMySiteValue = getSiteLockedStatus(siteDetails.data?.data.result) ?? false;

  const billingAdminBlock = useRoleGuard(['billing_admin'], 'block');

  return (
    <>
      <ContentViewCard>
        <List>
          <ListItem>
            <Box sx={{ ...settingsIcon }}>{<LockMySite />}</Box>
            <Grid container spacing={2} sx={{ ...settingsGrid }}>
              <Grid item xs={12} md={8}>
                <Typography variant="h3">{t('lock_my_site_title')}</Typography>
                <Typography sx={{ marginBottom: '1.25rem' }}>
                  {t('lock_my_site_description_item_1')}
                </Typography>
                <Typography sx={{ marginBottom: '1.25rem' }}>
                  {t('lock_my_site_description_item_2')}
                </Typography>
                <Typography>{t('lock_my_site_description_item_3')}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {!siteDetails.data || siteDetails.isLoading ? (
                  <Skeleton />
                ) : (
                  <ToggleSetting
                    onChange={val => (val.value === 1 ? enableLockSite() : disableLockSite())}
                    name="lock_my_site"
                    value={lockMySiteValue}
                    disabled={billingAdminBlock}
                  />
                )}
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </ContentViewCard>

      <ContentViewCard>
        <Stack
          divider={
            <Divider
              sx={{
                marginLeft: '-16px',
                marginRight: '-16px',
              }}
            />
          }
          spacing={1}
        >
          <List>
            <ListItem>
              <Box sx={{ ...settingsIcon }}>{<SSHAccess />}</Box>
              <Grid container spacing={2} sx={{ ...settingsGrid }}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h3">{t('password_protection_title')}</Typography>
                  <Typography>{t('password_protection_description')}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {!passwordProtection.data || passwordProtection.isLoading ? (
                    <Skeleton />
                  ) : (
                    <ToggleSetting
                      onChange={val => setSiteProtection(!!val.value)}
                      name="password_protection"
                      value={passwordProtectionValue}
                      disabled={billingAdminBlock}
                    />
                  )}
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <UserList />
        </Stack>
      </ContentViewCard>
    </>
  );
};

export default Security;
