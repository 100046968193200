import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TextField,
  Grid,
  CardContent,
  InputAdornment,
} from '@mui/material';
import PaginationControls from 'component/base/PaginationControls';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { SearchRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useActivityLog } from 'api/siteMetrics';
import { useGetSiteSettings, useSiteSetActivityLoggingState } from 'api/site';
import { formatDate } from 'utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { Gravatar } from 'component/base/Gravatar';
import { RoleGuard } from 'component/base/RoleGuard';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EMPTY_VALUE_PLACEHOLDER } from 'utils/formatting';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';
import { useEffect, useRef } from 'react';

const PREFIX = 'ActivityLog';

const classes = {
  tableCell: `${PREFIX}TableCell`,
  firstRow: `${PREFIX}FirstRow`,
  author: `${PREFIX}Author`,
};

const Root = styled('div')({
  [`& .${classes.tableCell}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.firstRow}`]: {
    verticalAlign: 'top',
    fontWeight: 'normal !important' as 'normal',
  },
  [`& .${classes.author}`]: {
    fontWeight: 'normal',
    verticalAlign: 'top',
  },
});

function htmlDecode(input: string) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

function UppercaseFirstLetter(str: string = '') {
  return `${str[0]?.toUpperCase() ?? ''}${str.slice(1)}`;
}

export default function InstalledThemeRowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}
export function ActivityLog({
  siteId,
  duration,
}: {
  readonly siteId: string;
  readonly duration: string;
}) {
  const { data: siteSettingsData, isPending: siteSettingsIsLoading } = useGetSiteSettings(siteId);

  const setActivityLoggingState = useSiteSetActivityLoggingState(siteId);

  const isEnabled = !!siteSettingsData?.data.result?.wp_activity_log;

  const tableState = usePaginatedListState(
    {
      searchKey: 'display_name',
      urlStatePrefix: 'activity-log',
      filterKeys: ['duration'],
    },
    { filters: { duration: '24h' } }
  );

  const isDurationInitialized = useRef(false);

  useEffect(() => {
    tableState.setFilter('duration', duration);
    isDurationInitialized.current = true;
  }, [duration]);

  const { data, isLoading } = useActivityLog(
    siteId,
    tableState,
    isEnabled && isDurationInitialized.current
  );

  const { t } = useTranslation();

  const activityLogItems = data?.data?.result || [];

  const searchInputProps = useDebouncedInputProps(tableState.params.search, tableState.setSearch);
  const loadingState = usePaginatedListLoadingState(
    !!activityLogItems.length,
    isLoading,
    tableState.params
  );

  const noDataTitle =
    tableState.params.filters.duration === '30d'
      ? t('no_data_yet')
      : t('no_results_during_this_timeframe');

  const isLoadingAny = isLoading || siteSettingsIsLoading || setActivityLoggingState.isPending;

  const renderTableRows = () => {
    if (isLoading) {
      return <InstalledThemeRowSkeleton />;
    }

    return activityLogItems.map(activityLogItem => {
      return (
        <TableRow key={String(activityLogItem.id)}>
          <TableCell className={classes.firstRow}>
            {activityLogItem.created_at ? (
              <>
                <Box>
                  {t('time_ago', {
                    time: formatDistanceToNow(new Date(activityLogItem.created_at)),
                  })}
                </Box>
                {formatDate({
                  date: activityLogItem.created_at,
                  dateStyle: 'short',
                  timeStyle: 'short',
                })}
              </>
            ) : (
              EMPTY_VALUE_PLACEHOLDER
            )}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box display="inline-block">
              <Gravatar
                name={activityLogItem?.display_name || ''}
                email={activityLogItem?.user_email || ''}
              />
            </Box>
            <Box display="inline-block" className={classes.author} paddingLeft={1}>
              <Box>
                {activityLogItem.display_name ? activityLogItem.display_name : t('unknown')}
              </Box>
              <Box>{UppercaseFirstLetter(activityLogItem.roles)}</Box>
            </Box>
          </TableCell>
          <TableCell className={classes.tableCell}>{activityLogItem.ip}</TableCell>
          <TableCell className={classes.tableCell}>
            {UppercaseFirstLetter(activityLogItem.type)}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {htmlDecode(activityLogItem?.label || EMPTY_VALUE_PLACEHOLDER)}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {UppercaseFirstLetter(activityLogItem.action)}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {htmlDecode(activityLogItem?.description || EMPTY_VALUE_PLACEHOLDER)}
          </TableCell>
        </TableRow>
      );
    });
  };

  const placeholder = t('search_authors_label');

  return (
    <Root>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} md="auto">
          <TextField
            {...searchInputProps}
            variant="outlined"
            placeholder={placeholder}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disableTypography component="button">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <RoleGuard roles={['billing_admin']} type="block">
          <Grid item>
            <ProgressiveButton
              onClick={() => setActivityLoggingState.mutateAsync(!isEnabled)}
              isLoading={isLoadingAny}
              text={isEnabled ? t('disable') : t('enable')}
            />
          </Grid>
        </RoleGuard>
      </Grid>
      {isEnabled ? (
        <CardContent>
          <TableContainer>
            <Table aria-label="Site Activity Logs">
              <TableHead>
                <TableRow>
                  <TableCell width={170}>{t('date')}</TableCell>
                  <TableCell width={170}>{t('author')}</TableCell>
                  <TableCell>{t('activity_log_table_ip')}</TableCell>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('label')}</TableCell>
                  <TableCell>{t('action')}</TableCell>
                  <TableCell>{t('description')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingState === 'noResults' ? (
                  <NoResultsFound colSpan={7} />
                ) : loadingState === 'noData' ? (
                  <NoResultsFound colSpan={7} message={noDataTitle} />
                ) : (
                  renderTableRows()
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <PaginationControls
            page={tableState.params.page}
            totalRowCount={data?.data.metadata?.total || 0}
            perPage={tableState.params.perPage}
            perPageOptions={tableState.config.perPageOptions}
            onPageChange={tableState.setPage}
            onPerPageChange={tableState.setPerPage}
          />
        </CardContent>
      ) : null}
    </Root>
  );
}
