import { useState } from 'react';
import PaginationControls from 'component/base/PaginationControls';
import {
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router';
import NoResultsFound from 'component/base/NoResultsTableRow';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { DeleteRounded } from '@mui/icons-material';
import { useDeleteSiteProtectionUser, usePasswordProtectionUsers } from 'api/security';
import { CreateUser } from './CreateUser';
import { RoleGuard } from 'component/base/RoleGuard';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';

export interface ConfirmationModal {
  open: boolean;
  userId: string | null;
  username: string | null;
  type: 'delete';
}

const initConfirmation: ConfirmationModal = {
  open: false,
  userId: null,
  username: null,
  type: 'delete',
};

function RowsSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

const UserList = () => {
  const { t } = useTranslation();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const tableState = usePaginatedListState();

  const { data, isLoading } = usePasswordProtectionUsers(siteId, tableState);

  const users = data?.data.result?.users ?? [];

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>(initConfirmation);
  const { mutateAsync: deleteUser, isPending: deleteLoading } = useDeleteSiteProtectionUser({
    siteId,
  });

  const handleDeleteUser = async () => {
    if (confirmationModal.userId) {
      await deleteUser(confirmationModal.userId);
      setConfirmationModal(initConfirmation);
      enqueueSnackbar(t('security_delete_user_success'), { variant: 'success' });
    }
  };

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const closeModal = () => setConfirmModal(false);

  const renderConfirmationDescription = () => {
    if (confirmationModal.type === 'delete') {
      return t('security_delete_user_confirmation', { username: confirmationModal.username });
    } else {
      return null;
    }
  };

  return (
    <>
      <RoleGuard roles={['billing_admin']} type="block">
        <Box alignSelf="end">
          <Button onClick={() => setConfirmModal(true)} variant="contained" color="primary">
            {t('add_user')}
          </Button>
        </Box>
      </RoleGuard>
      <TableContainer
        sx={{
          marginTop: '0px',
        }}
      >
        <Table aria-label={'users table'}>
          <TableHead>
            <TableRow>
              <TableCell>{t('username')}</TableCell>
              <RoleGuard roles={['billing_admin']} type="block">
                <TableCell width="90px">{t('action')}</TableCell>
              </RoleGuard>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <RowsSkeleton />
            ) : users?.length === 0 ? (
              <NoResultsFound colSpan={2} />
            ) : (
              users.map(user => {
                return (
                  <TableRow key={user.user_id}>
                    <TableCell>{user.username}</TableCell>
                    <RoleGuard roles={['billing_admin']} type="block">
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setConfirmationModal({
                              open: true,
                              type: 'delete',
                              userId: user?.user_id || '',
                              username: user?.username || '',
                            })
                          }
                          color="primary"
                          size="large"
                        >
                          <DeleteRounded />
                        </IconButton>
                      </TableCell>
                    </RoleGuard>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isLoading && (
        <PaginationControls
          page={tableState.params.page}
          totalRowCount={data?.data.metadata?.total || 0}
          perPage={tableState.params.perPage}
          perPageOptions={tableState.config.perPageOptions}
          onPageChange={tableState.setPage}
          onPerPageChange={tableState.setPerPage}
        />
      )}
      <CreateUser siteId={siteId} open={confirmModal} onClose={closeModal} />

      <ConfirmationDialog
        action={confirmationModal.type === 'delete' ? 'delete' : 'confirm'}
        forceLoadingState={Boolean(deleteLoading)}
        onClose={() => setConfirmationModal(initConfirmation)}
        open={confirmationModal.open}
        onConfirm={confirmationModal.type === 'delete' ? handleDeleteUser : undefined}
        title={(confirmationModal.type === 'delete' && t('security_delete_user')) ?? ''}
        description={renderConfirmationDescription()}
      />
    </>
  );
};

export default UserList;
